import styles from "./tableStyles.module.css";

import type { CustomHeaderProps } from "ag-grid-react";

export interface YearHeaderCellProps extends CustomHeaderProps {
  from: string;
  to: string;
}

export default (props: YearHeaderCellProps) => {
  return (
    <div style={{ display: "flex" }}>
      <div className={styles.headerContainer}>
        <div className={styles.yearHeader}>{props.displayName}</div>
        <div className={styles.fromToContainer}>
          <div>{props.from}</div>
          <div>-</div>
          <div>{props.to}</div>
        </div>
      </div>
    </div>
  );
};
