import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { labels } from "containers/collapseTable/final";
import dayjs from "dayjs";
import { FinalTableData } from "hooks/common/finalAdapter";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import styles from "./tableStyles.module.css";
import YearHeaderCell from "./YearHeaderCell";
import LogoHeaderCell from "./LogoHeaderCell";
import LinkCell from "./LinkCell";
import { getLink } from "./tableLink.util";
import { customThemeConfig, Section } from "./tableUtils";
import { Rate } from "store/auth/types";
import useMtdc from "hooks/useMtdc";
import { TableData } from "hooks/common/mtdcAdapter";

function getSectionData(
  section: Section,
  grantId: string,
  { rates, tableData }: { rates: Rate[]; tableData: TableData }
) {
  const rows = [];

  let item: Record<string, any> = {
    title: section.name,
    section: section.name,
    subSection: "",
    total: section.totalsItem?.totals,
    path: [section.name],
    isIndirectSection: section.name === "Indirect costs",
    rates,
    tableData,
  };

  section.totalsItem?.years.forEach((i, index) => {
    item["year" + index] = i.value;
  });

  if (!section.totalsItem) {
    for (const key in section.sections) {
      const subSection = section.sections[key];

      subSection.totalsItem?.years.forEach((i, index) => {
        item["year" + index] = i.value;
      });

      item.total = subSection.totalsItem.totals;
      break;
    }
  }

  rows.push(item);

  for (const key in section.sections) {
    const subSection = section.sections[key];

    const title = labels[subSection.name] || subSection.name;

    if (!title) {
      break;
    }

    let item: Record<string, any> = {
      title,
      section: section.name,
      subSection: subSection.name,
      total: subSection.totalsItem.totals,
      path: [section.name, subSection.name],
    };

    subSection.totalsItem.years.forEach((i, index) => {
      item["year" + index] = i.value;
    });
    rows.push(item);

    for (const _item of subSection.items) {
      const itemTitle = labels[_item.name] || _item.name;

      if (!title) {
        break;
      }

      console.log(subSection);

      let item: Record<string, any> = {
        title: itemTitle,
        section: section.name,
        subSection: subSection.name,
        total: _item.totals,
        path: [section.name, subSection.name, itemTitle],
        link: getLink(_item, subSection.section, grantId),
      };

      _item.years.forEach((i, index) => {
        item["year" + index] = i.value;
      });
      rows.push(item);
    }
  }
  return rows;
}

function useRowData(data: FinalTableData, grantId: string, rates: Rate[]) {
  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [bottomRow, setBottomRow] = useState<any[]>([]);
  const { tableData, getMTDC } = useMtdc();
  useEffect(() => {
    getMTDC(grantId);
  }, [grantId]);

  useEffect(() => {
    let totalRow: Record<string, any> = {
      section: "Total",
      title: data.totalsItem.name,
      subSection: data.totalsItem.name,
      total: data.totalsItem.totals,
      path: ["Total"],
    };
    data.totalsItem.years.forEach((item, index) => {
      totalRow["year" + index] = item.value;
    });

    setBottomRow([totalRow]);

    setRowData([
      ...getSectionData(data.sections.personnel, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.otherPersonnel, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.fringeBenefits, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.travel, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.participantSupport, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.equipment, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.otherDirectCosts, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.directCosts, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.indirectCost, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.totalDirectAndIndirectCosts, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.fees, grantId, {
        rates,
        tableData,
      }),
      ...getSectionData(data.sections.costSharingExpenses, grantId, {
        rates,
        tableData,
      }),
    ]);

    setColDefs([
      ...data.years.map((item, index) => ({
        headerComponentParams: {
          innerHeaderComponent: YearHeaderCell,
          innerHeaderComponentParams: {
            from: dayjs(item[0]).format("M/D/YYYY"),
            to: dayjs(item[1]).format("M/D/YYYY"),
          },
        },
        field: "year" + index,
        headerName: `Year ${index + 1}`,
        filter: "agSetColumnFilter",
        valueFormatter: ({ value }: ValueFormatterParams) =>
          value == null ? "" : numeral(value).format("$0,0.00"),
        cellClass: (params: any) => {
          if (params.node.rowPinned === "bottom") {
            return styles.footerCell;
          }
          return params.data.subSection === ""
            ? styles.groupCell
            : styles.center;
        },
      })),
      {
        headerName: "Total",
        field: "total",
        pinned: "right",
        lockPosition: true,
        width: 120,
        // type: "rightAligned",
        filter: "agSetColumnFilter",
        valueFormatter: ({ value }: ValueFormatterParams) =>
          value == null ? "" : numeral(value).format("$0,0.00"),
        cellClass: (params: any) => {
          if (params.node.rowPinned === "bottom") {
            return styles.footerCell;
          }
          return params.data.subSection === ""
            ? styles.groupCell
            : styles.center;
        },
      },
    ]);
  }, [data]);

  return { rowData, colDefs, bottomRow };
}

export function FinalTable({
  grantId,
  data,
  height = 400,
  rates,
}: {
  grantId: string;
  data: FinalTableData;
  height?: number;
  rates: Rate[];
}) {
  const { rowData, colDefs, bottomRow } = useRowData(data, grantId, rates);
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Final Budget",
      headerComponentParams: {
        innerHeaderComponent: LogoHeaderCell,
      },
      pinned: "left",
      lockPosition: true,
      field: "title",
      cellRenderer: "agGroupCellRenderer",
      filter: "agSetColumnFilter",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: LinkCell,
      },
      cellClass: (params: any) => {
        if (params.node.rowPinned === "bottom") {
          return styles.footerCell;
        }
        return params.data.subSection === "" ? styles.groupCell : "";
      },
    };
  }, []);

  return (
    <div
      // define a height because the Data Grid will fill the size of the parent container
      style={{ height }}
    >
      <AgGridReact<any>
        // @ts-ignore
        autoGroupColumnDef={autoGroupColumnDef}
        groupDisplayType="singleColumn"
        rowData={rowData}
        columnDefs={colDefs}
        headerHeight={80}
        treeData
        getDataPath={(data) => data.path}
        pinnedBottomRowData={bottomRow}
        theme={customThemeConfig}
        cellSelection={true}
        enableCharts
        allowContextMenuWithControlKey
      />
    </div>
  );
}
