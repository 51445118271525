import { TableDataItem } from "hooks/common/mtdcAdapter";

export const personalTypesLinks: Record<string, string> = {
  seniorPersonnel: "senior-personal",
  academicResearchAssociate: "academic-research-personal",
  postDocPersonnel: "post-doc",
  civilService: "civil-service",
  unionPersonnel: "union",
  tempAndCasual: "temp-n-casual",
  otherPersonnel: "other-personnel",
  undergraduateStudentsAcademic: "undergraduate-students",
  undergraduateStudentsSummer: "undergraduate-students",
  graduateStudentsStipend: "graduate-students",
};

export const otherLinkTypes: Record<string, string> = {
  materialsAndSupplies: "materials-and-supplies",
  publications: "publications",
  computerServices: "computer-services",
  consultans: "consultantServices",
  subawards: "subawards",
  contracts: "contracts",
  incentivePayments: "payments",
  allOtherCosts: "otherExpenses",
};

export function getLink(item: TableDataItem, section: string, grantId: string) {
  if (["personnel.salaries", "personnel.fringeBenefits"].includes(section)) {
    const personnelLink = personalTypesLinks[item.key];
    return `/grants/${grantId}/personnel/${personnelLink}/${item.id}/edit`;
  }
  if (
    ["otherDirectCosts", "other", "otherDirectCosts.other"].includes(section)
  ) {
    const odcLink = otherLinkTypes[item.key];
    return `/grants/${grantId}/otherDC/${odcLink}/${item.id}/edit`;
  }
  if (["travel"].includes(section)) {
    return `/grants/${grantId}/travel/grantPersonnel/${item.id}/edit`;
  }
  if (["participantSupport"].includes(section)) {
    return `/grants/${grantId}/events/${item.id}/edit`;
  }
  if (["directCosts"].includes(section)) {
    return `/grants/${grantId}/mtdc`;
  }
  if (["costSharingExpenses"].includes(section)) {
    return `/grants/${grantId}/costSharing/${item.id}/edit`;
  }
  return `/grants/${grantId}/${section}/${item.id}/edit`;
}
