import { ColDef, ValueFormatterParams } from "ag-grid-community";

import { AgGridReact } from "ag-grid-react";
import { labels } from "containers/collapseTable/final";
import dayjs from "dayjs";
import { TableData } from "hooks/common/mtdcAdapter";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import styles from "./tableStyles.module.css";
import YearHeaderCell from "./YearHeaderCell";
import LogoHeaderCell from "./LogoHeaderCell";
import LinkCell from "./LinkCell";
import { customThemeConfig, Section } from "./tableUtils";
import { getLink } from "./tableLink.util";

const DISABLED_ROWS = [
  "undergraduateStudentsAcademic",
  "undergraduateStudentsSummer",
  "graduateStudentsStipend",
  "graduateStudentsAcademic",
  "graduateStudentsSummer",
  "graduateStudentsStipend",
  "graduateStudentsTuition",
];

function getSectionData(section: Section, grantId: string) {
  const rows = [];

  let item: Record<string, any> = {
    id: section.name,
    title: section.name,
    section: section.name,
    subSection: "",
    total: section.totalsItem?.totals,
    path: [section.name],
  };

  section.totalsItem?.years.forEach((i, index) => {
    item["year" + index] = i.value;
  });

  if (!section.totalsItem) {
    for (const key in section.sections) {
      const subSection = section.sections[key];

      subSection.totalsItem?.years.forEach((i, index) => {
        item["year" + index] = i.value;
      });

      item.total = subSection.totalsItem.totals;
      break;
    }
  }

  rows.push(item);

  for (const key in section.sections) {
    const subSection = section.sections[key];

    const title = labels[subSection.name] || subSection.name;

    if (!title) {
      break;
    }

    let item: Record<string, any> = {
      id: subSection.name,
      title,
      section: section.name,
      subSection: subSection.name,
      total: subSection.totalsItem.totals,
      path: [section.name, subSection.name],
      showMTDC: true,
      isMTDC: section.totalsItem?.isMTDC,
    };

    subSection.totalsItem.years.forEach((i, index) => {
      item["year" + index] = i.value;
    });
    rows.push(item);
    for (const _item of subSection.items) {
      const itemTitle = labels[_item.name] || _item.name;

      if (!title) {
        break;
      }

      console.log(subSection);

      let item: Record<string, any> = {
        title: itemTitle,
        section: section.name,
        subSection: subSection.name,
        total: _item.totals,
        path: [section.name, subSection.name, itemTitle],
        link: getLink(_item, subSection.section, grantId),
      };

      _item.years.forEach((i, index) => {
        item["year" + index] = i.value;
      });
      rows.push(item);
    }
  }
  return rows;
}

function useRowData(data: TableData, grantId: string) {
  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [bottomRow, setBottomRow] = useState<any[]>([]);

  useEffect(() => {
    let totalRow: Record<string, any> = {
      section: "Total",
      title: data.totalsItem.name,
      subSection: data.totalsItem.name,
      total: data.totalsItem.totals,
      path: ["Total"],
    };
    data.totalsItem.years.forEach((item, index) => {
      totalRow["year" + index] = item.value;
    });

    setBottomRow([totalRow]);

    setRowData([
      ...getSectionData(data.sections.personnel, grantId),
      ...getSectionData(data.sections.otherPersonnel, grantId),
      ...getSectionData(data.sections.fringeBenefits, grantId),
      ...getSectionData(data.sections.travel, grantId),
      ...getSectionData(data.sections.participantSupport, grantId),
      ...getSectionData(data.sections.equipment, grantId),
      ...getSectionData(data.sections.otherDirectCosts, grantId),
    ]);

    setColDefs([
      ...data.years.map((item, index) => ({
        headerName: `Year ${index + 1}`,
        lockPosition: true,
        headerComponentParams: {
          innerHeaderComponent: YearHeaderCell,
          innerHeaderComponentParams: {
            from: dayjs(item.year[0]).format("M/D/YYYY"),
            to: dayjs(item.year[1]).format("M/D/YYYY"),
          },
        },
        field: "year" + index,
        cellClass: (params: any) => {
          if (params.node.rowPinned === "bottom") {
            return styles.footerCell;
          }
          return params.data.subSection === ""
            ? styles.groupCell
            : styles.center;
        },
      })),
      {
        headerName: "Total",
        field: "total",
        pinned: "right",
        lockPosition: true,
        width: 120,
        valueFormatter: ({ value }: ValueFormatterParams) =>
          value == null ? "" : numeral(value).format("$0,0.00"),
        cellClass: (params: any) => {
          if (params.node.rowPinned === "bottom") {
            return styles.footerCell;
          }
          return params.data.subSection === ""
            ? styles.groupCell
            : styles.center;
        },
      },
      {
        headerName: "Attributed to MTDC",
        field: "isMTDC",
        pinned: "right",
        lockPosition: true,
        width: 120,
        editable: true,
        cellClass: (params: any) => {
          if (params.node.rowPinned === "bottom") {
            return styles.footerCell;
          }
          return params.data.subSection === "" ? styles.groupCell : "";
        },
        cellRendererSelector: (params) => {
          if (!!params.data.showMTDC) {
            return {
              component: "agCheckboxCellRenderer",
              params: {
                disabled: DISABLED_ROWS.includes(params.data.id),
              },
            };
          }
          return undefined;
        },
      },
    ]);
  }, [data]);

  return { rowData, colDefs, bottomRow };
}

export function MdtcTable({
  data,
  height = 400,
  grantId,
}: {
  data: TableData;
  height?: number;
  grantId: string;
}) {
  const { rowData, colDefs, bottomRow } = useRowData(data, grantId);
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "MDTC Budget",
      headerComponentParams: {
        innerHeaderComponent: LogoHeaderCell,
      },
      pinned: "left",
      lockPosition: true,
      field: "title",
      cellRenderer: "agGroupCellRenderer",
      filter: "agSetColumnFilter",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: LinkCell,
      },
      cellClass: (params: any) => {
        if (params.node.rowPinned === "bottom") {
          return styles.footerCell;
        }
        return params.data.subSection === "" ? styles.groupCell : "";
      },
    };
  }, []);

  return (
    <div
      // define a height because the Data Grid will fill the size of the parent container
      style={{ height }}
    >
      <AgGridReact<any>
        // @ts-ignore
        autoGroupColumnDef={autoGroupColumnDef}
        groupDisplayType="singleColumn"
        rowData={rowData}
        columnDefs={colDefs}
        treeData
        getDataPath={(data) => data.path}
        pinnedBottomRowData={bottomRow}
        theme={customThemeConfig}
        getRowId={(param) => param.data.id}
        headerHeight={80}
        cellSelection={true}
        enableCharts
        allowContextMenuWithControlKey
      />
    </div>
  );
}
