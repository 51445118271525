import styles from "./tableStyles.module.css";

import type { CustomHeaderProps } from "ag-grid-react";

import logo from "./logo-03.png";

export interface LogoHeaderCellProps extends CustomHeaderProps {}

export default (props: LogoHeaderCellProps) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <img src={logo} style={{ height: 40, objectFit: "contain" }} />
      <div className={styles.yearHeader}>{props.displayName}</div>
    </div>
  );
};
