import { FC, useEffect, useMemo, useState } from "react";
import { PublicHeader } from "containers/header";
import { useParams } from "react-router-dom";
import CollapseTable from "containers/collapseTable";
import useFinalForm from "hooks/useFinalForm";
import useRates from "hooks/useRates";
import CollapseInfo from "components/collapseInfo";

import styles from "./finalForm.module.css";
import useMtdc from "hooks/useMtdc";
import useIndirect from "hooks/useIndirectCosts";
import IndirectCostsTable from "containers/collapseTable/indirect";
import useGrants from "hooks/useGrants";
import { FinalTable } from "components/grid/FinalTable";
import { IndirectCostTable } from "components/grid/IndirectCostTable";
import { MdtcTable } from "components/grid/mdtcTable";

const ShareFinalForm: FC = () => {
  const params: Record<string, string> = useParams();
  const [loaded, setLoaded] = useState(false);
  const { getOne, grant } = useGrants();

  const grantId = params["grantId"];

  useEffect(() => {
    getOne(grantId);
  }, []);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/da83fe63-ffc7-40b8-8903-106ca3e85af2/platform.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [loaded]);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/731ef958-7af8-4e43-b98b-47f1b6b5fd95/platform.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [loaded]);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/b722c317-d49c-43be-bdce-df4e094dd234/platform.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [loaded]);

  return (
    <div>
      <PublicHeader />

      <div
        style={{
          marginTop: 88,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1280,
          padding: "1em",
        }}
      >
        <p style={{ fontSize: "1.3em" }}>
          {grant.primaryInvestigator.fullName}
        </p>
        <p>{grant.organization.name}</p>
        <p>Please click on each section to view each part of the budget.</p>
        <h1 style={{ textAlign: "center" }}>{grant.title}</h1>

        <CollapseInfo
          arrow
          className={styles.info}
          title="What is Budget Mentor?"
          onOpenChange={(open) => setLoaded(open)}
          text={
            <div>
              <p>
                Budget Mentor is a software platform designed to simplify the
                process of creating, managing, exporting and sharing compliant
                research budgets, with a primary focus on National Science
                Foundation (NSF) grants. Tailored for researchers,
                administrators, and grant professionals, Budget Mentor provides
                an intuitive, non-spreadsheet interface that guides users
                through every step of the budget creation process. By
                transforming complex requirements into simple, easy-to-complete
                prompts, the platform reduces errors, streamlines collaboration,
                and enhances efficiency.
              </p>
              <p>Key features include:</p>
              <ul>
                <li>
                  Comprehensive compliance guidance aligned with NSF policies.
                </li>
                <li>
                  Automated budget calculations for fringe benefits, indirect
                  costs, and more.
                </li>
                <li>
                  Real-time tracking of budget caps and significant changes.
                </li>
                <li>
                  Export-ready formats compatible with institutional systems.
                </li>
                <li>
                  Collaboration tools for research teams and administrators.
                </li>
              </ul>
              <p>
                Budget Mentor is ideal for research administrators aiming to
                streamline their workflows and for researchers seeking to
                independently draft budgets with confidence. With its
                user-friendly design and advanced functionality, Budget Mentor
                empowers users to focus on their research while ensuring their
                budgets meet professional and institutional standards.
              </p>
              <div>
                <div
                  className="senja-embed"
                  data-id="da83fe63-ffc7-40b8-8903-106ca3e85af2"
                  data-mode="shadow"
                  data-lazyload="false"
                  style={{ display: "block" }}
                ></div>
                <div
                  className="senja-embed"
                  data-id="731ef958-7af8-4e43-b98b-47f1b6b5fd95"
                  data-mode="shadow"
                  data-lazyload="false"
                  style={{ display: "block", marginTop: 10, marginBottom: 10 }}
                ></div>
                <div
                  className="senja-embed"
                  data-id="b722c317-d49c-43be-bdce-df4e094dd234"
                  data-mode="shadow"
                  data-lazyload="false"
                  style={{ display: "block" }}
                ></div>
              </div>
            </div>
          }
        />
        <CollapseInfo
          arrow
          className={styles.info}
          title="MDTC Table"
          text={<MdtcTableInternal grantId={grantId} />}
        />
        <CollapseInfo
          arrow
          className={styles.info}
          title="Calculation of Indirect Costs Table"
          text={<IndirectFactsTable grantId={grantId} />}
        />
        <CollapseInfo
          arrow
          className={styles.info}
          title="Indirect Costs"
          text={<IndirectCostsTableInternal grantId={grantId} />}
        />
        <CollapseInfo
          arrow
          className={styles.info}
          title="Final Table"
          text={<LocalFinalTable grantId={grantId} />}
        />
      </div>
    </div>
  );
};

function IndirectCostsTableInternal({ grantId }: { grantId: string }) {
  const { getIndirectCosts, tableData } = useIndirect({
    form_page_type: "General info",
  });

  useEffect(() => {
    getIndirectCosts(grantId);
  }, [grantId]);

  const rows = useMemo(() => {
    return [
      tableData.sections.personnel,
      tableData.sections.otherPersonnel,
      tableData.sections.fringeBenefits,
      tableData.sections.travel,
      tableData.sections.participantSupport,
      tableData.sections.equipment,
      tableData.sections.otherDirectCosts,
    ];
  }, [tableData]);
  return (
    <IndirectCostsTable //@ts-ignore
      rows={rows}
      data={tableData}
      editable={false}
      grantId={grantId}
    />
  );
}

function IndirectFactsTable({ grantId }: { grantId: string }) {
  const { tableData, getMTDC } = useMtdc();
  const { getGrantRatesFromStore, getGrantRates } = useRates();
  const grantRates = getGrantRatesFromStore(grantId);

  useEffect(() => {
    getMTDC(grantId);
    getGrantRates(grantId);
  }, [grantId]);

  // return <ModalIndirectTables tableData={tableData} rates={grantRates} />;

  return <IndirectCostTable grantId={grantId} data={tableData} />;
}

function LocalFinalTable({ grantId }: { grantId: string }) {
  const { getTable, tableData } = useFinalForm();
  const { getGrantRatesFromStore, getGrantRates } = useRates();
  const grantRates = getGrantRatesFromStore(grantId);

  useEffect(() => {
    getTable(grantId);
    getGrantRates(grantId);
  }, [grantId]);

  const rows = useMemo(() => {
    return [
      tableData.sections.personnel,
      tableData.sections.otherPersonnel,
      tableData.sections.fringeBenefits,
      tableData.sections.travel,
      tableData.sections.participantSupport,
      tableData.sections.equipment,
      tableData.sections.otherDirectCosts,
      tableData.sections.directCosts,
      tableData.sections.indirectCost,
      tableData.sections.totalDirectAndIndirectCosts,
      tableData.sections.fees,
      tableData.sections.costSharingExpenses,
    ];
  }, [tableData]);

  return <FinalTable grantId={grantId} data={tableData} rates={grantRates} />;

  // return (
  //   <CollapseFinalTable //@ts-ignore
  //     rows={rows}
  //     data={tableData}
  //     grantId={grantId}
  //     rates={grantRates}
  //   />
  // );
}

function MdtcTableInternal({ grantId }: { grantId: string }) {
  const { getMTDC, tableData, submitLoading } = useMtdc({
    form_page_type: "General info",
  });

  useEffect(() => {
    getMTDC(grantId);
  }, [grantId]);

  const rows = useMemo(() => {
    return [
      tableData.sections.personnel,
      tableData.sections.otherPersonnel,
      tableData.sections.fringeBenefits,
      tableData.sections.travel,
      tableData.sections.participantSupport,
      tableData.sections.equipment,
      tableData.sections.otherDirectCosts,
    ];
  }, [tableData]);

  return <MdtcTable grantId={grantId} data={tableData} />;

  // return (
  //   <CollapseTable //@ts-ignore
  //     rows={rows}
  //     data={tableData}
  //     loading={submitLoading}
  //     grantId={params.id}
  //   />
  // );
}

export default ShareFinalForm;
