import type { CustomCellRendererProps } from "ag-grid-react";
import Modal from "components/modal";
import { ModalIndirectTables } from "containers/collapseTable/final";
import { TableData } from "hooks/common/mtdcAdapter";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styles from "./tableStyles.module.css";

import { Link } from "react-router-dom";
import { Rate } from "store/auth/types";

export interface LinkCellProps extends CustomCellRendererProps {}

export default (props: LinkCellProps) => {
  if (props.data.link) {
    return (
      <Link
        style={{ fontSize: 16, textDecoration: "underline" }}
        to={props.data.link}
      >
        {props.value}
      </Link>
    );
  }
  if (props.data.isIndirectSection) {
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
        <span>{props.value}</span>
        <IndirectCostsModal
          rates={props.data.rates}
          tableData={props.data.tableData}
        />
      </div>
    );
  }

  return <span>{props.value}</span>;
};

function IndirectCostsModal({
  rates,
  tableData,
}: {
  rates: Rate[];
  tableData: TableData;
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <span className={styles.link} onClick={() => setShow(true)}>
        Calculation of Total Indirect Costs
      </span>
      {createPortal(
        <Modal
          visible={show}
          fb
          onCancel={() => setShow(false)}
          title="The following data were used to calculate Total Indirect costs"
        >
          <ModalIndirectTables rates={rates} tableData={tableData} />
        </Modal>,
        document.body
      )}
    </>
  );
}
