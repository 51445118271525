import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import cx from "classnames";
import dayjs from "dayjs";
import Head from "containers/head";
import useAuth from "hooks/useAuth";
import Typo from "components/typo";
import Spinner from "components/spinner";
import { Button } from "components/form";
import logo from "images/logo_white.svg";
import Wizard from "./wizard";
import b from "../images/b.svg";
import styles from "./signUp.module.css";
import { useMixPanel } from "../../../hooks/useMixPanel";
import queryString from "query-string";

const year = dayjs().get("year").toString();

export type Field = Record<string, string | boolean | null | Date>;
const SignUp: FC = () => {
  const { pageViewed, emailVerified } = useMixPanel();
  const params: Record<string, string> = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const { confirmEmail, resendEmail, loading, emailConfirmed } = useAuth();

  const searchParams = queryString.parse(search);

  useEffect(() => {
    if (params.hash) {
      confirmEmail({ hash: params.hash }, () => {
        history.push("/sign-up?step=heardof&email=" + searchParams.email);
      });
    } //@ts-ignore

    //track mix panel Page Viewed event
    pageViewed("Confirm Email");
  }, []);

  useEffect(() => {
    if (emailConfirmed) {
      emailVerified();
    }
  }, [emailConfirmed, emailVerified]);

  const title = useMemo(() => {
    let text = emailConfirmed
      ? "Verification complete"
      : "Verification link is invalid";
    if (loading) {
      text = "Verification in progress";
    }
    return text;
  }, [loading, emailConfirmed]);

  const onSubmit = useCallback(() => {
    if (params.hash) {
      resendEmail(
        { hash: params.hash },
        `We sent a confirmation link to your email`
      );
    }
  }, [resendEmail, params]);

  const subtitle =
    emailConfirmed || loading
      ? ""
      : "You clicked on not the most recent link. This link is no longer active.";

  return (
    <div className={styles.wrapper}>
      <Head title="Sign Up" />
      <div className={cx(styles.leftSide, styles.side)}>
        <img src={logo} alt="logo" draggable={false} className={styles.logo} />
        <img src={b} alt="b" draggable={false} className={styles.b} />
        <Wizard current="verify" />
        <Typo className={styles.year}>{year} © Budget Mentor</Typo>
      </div>
      <div className={cx(styles.side, styles.rightSide)}>
        <div className={styles.rightSideContent}>
          <div className={styles.content}>
            <Typo type="h3" className={styles.title}>
              {title}
            </Typo>
            {subtitle ? (
              <Typo type="p" size={16} className={styles.verifySubtitle}>
                {subtitle}
              </Typo>
            ) : null}
            <Typo type="div" className={styles.verifyText}>
              <>
                To get an email with the active account verification link,
                <Button
                  type="link"
                  name="resend"
                  onClick={onSubmit}
                  className={styles.greenText}
                >
                  click here.
                </Button>
              </>
            </Typo>
          </div>
        </div>
        {loading ? <Spinner full className={styles.spinner} /> : null}
      </div>
    </div>
  );
};

export default SignUp;
