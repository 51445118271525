import {
  AllCommunityModule,
  iconSetQuartzLight,
  ModuleRegistry,
  themeQuartz,
} from "ag-grid-enterprise";
import { AgChartsEnterpriseModule } from "ag-charts-enterprise";
import {
  RowGroupingModule,
  RowGroupingPanelModule,
  TreeDataModule,
  CellSelectionModule,
  ClipboardModule,
  ColumnMenuModule,
  ContextMenuModule,
  ExcelExportModule,
  IntegratedChartsModule,
  SetFilterModule,
} from "ag-grid-enterprise";
import { TableDataItem, TableSections } from "hooks/common/mtdcAdapter";

ModuleRegistry.registerModules([
  AllCommunityModule,
  RowGroupingModule,
  RowGroupingPanelModule,
  TreeDataModule,
  CellSelectionModule,
  ClipboardModule,
  ExcelExportModule,
  ColumnMenuModule,
  ContextMenuModule,
  CellSelectionModule,
  SetFilterModule,
  IntegratedChartsModule.with(AgChartsEnterpriseModule),
]);

export type Section = {
  name: string;
  sections?: TableSections;
  totalsItem?: TableDataItem;
};

export const customThemeConfig = themeQuartz
  .withPart(iconSetQuartzLight)
  .withParams({
    accentColor: "#3E5475",
    backgroundColor: "#FFFFFF",
    borderColor: "#0000003D",
    browserColorScheme: "light",
    cellTextColor: "#2F4F4F",
    fontFamily: {
      googleFont: "Inter",
    },
    fontSize: 16,
    headerBackgroundColor: "#14B4A7",
    headerFontSize: 16,
    headerFontWeight: 500,
    headerTextColor: "#FFFFFF",
    rowBorder: { style: "solid", width: 1, color: "#0000003D" },
    columnBorder: { style: "solid", color: "#0000003D" },
  });
