import {
  AllCommunityModule,
  ColDef,
  ICellRendererParams,
  iconSetQuartzLight,
  ModuleRegistry,
  themeQuartz,
  ValueFormatterParams,
} from "ag-grid-community";
import {
  RowGroupingModule,
  RowGroupingPanelModule,
  TreeDataModule,
} from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { CommentedItem, labels } from "containers/collapseTable/indirect";
import dayjs from "dayjs";
import {
  TableData,
  TableDataItem,
  TableSections,
} from "hooks/common/mtdcAdapter";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import styles from "./tableStyles.module.css";
import { MessageSquareTextIcon } from "lucide-react";
import { IndirectCommentModal } from "./IndirectCommentModal";
import { concat, get, set } from "lodash";
import { Button } from "components/form";
import {
  IUpdateMTDCFlagsInput,
  IEditMTDCFlagInput,
} from "containers/collapseTable/indirect";
import LogoHeaderCell from "./LogoHeaderCell";
import LinkCell from "./LinkCell";
import { getLink } from "./tableLink.util";
import { customThemeConfig, Section } from "./tableUtils";

const EDITABLE_ROWS = [
  "undergraduateStudentsAcademic",
  "undergraduateStudentsSummer",
  "graduateStudentsStipend",
  "graduateStudentsAcademic",
  "graduateStudentsSummer",
  "graduateStudentsStipend",
  "graduateStudentsTuition",
];

function CommentCellRenderer(props: ICellRendererParams) {
  const section = props.data.sectionData;
  console.log();
  return (
    <div>
      <MessageSquareTextIcon
        onClick={() =>
          (props as any).openModal({
            way: section.section,
            key: section.name,
            dataItem: section.totalsItem,
            name: section.totalsItem.name,
          })
        }
        style={{ height: 16, width: 16 }}
      />
    </div>
  );
}

function getSectionData(
  section: Section,
  selectedRows: string[],
  grantId: string
) {
  const rows = [];

  let item: Record<string, any> = {
    id: section.name,
    title: section.name,
    section: section.name,
    subSection: "",
    total: section.totalsItem?.totals,
    path: [section.name],
    canEdit: false,
  };

  section.totalsItem?.years.forEach((i, index) => {
    item["year" + index] = i.value;
  });

  if (!section.totalsItem) {
    for (const key in section.sections) {
      const subSection = section.sections[key];

      subSection.totalsItem?.years.forEach((i, index) => {
        item["year" + index] = i.value;
      });

      item.total = subSection.totalsItem.totals;
      break;
    }
  }

  rows.push(item);

  for (const key in section.sections) {
    const subSection = section.sections[key];

    const title = labels[subSection.name] || subSection.name;

    if (!title) {
      break;
    }

    let item: Record<string, any> = {
      title,
      id: subSection.name,
      section: section.name,
      subSection: subSection.name,
      total: subSection.totalsItem.totals,
      path: [section.name, subSection.name],
      canEdit: EDITABLE_ROWS.includes(subSection.name),
      isEditing: false,
      sectionData: subSection,
    };

    subSection.totalsItem.years.forEach((i, index) => {
      item["year" + index] = i.value;
    });
    rows.push(item);

    if (selectedRows.includes(subSection.name)) {
      let item: Record<string, any> = {
        title: `${title} (overridden)`,
        id: subSection.name + "overridden",
        section: section.name,
        subSection: subSection.name,
        total: subSection.totalsItem.totals,
        path: [section.name, subSection.name + "overridden"],
        canEdit: false,
        isOverride: true,
        isEditing: false,
        sectionData: subSection,
      };

      subSection.totalsItem.years.forEach((i, index) => {
        item["year" + index] = i.overriddenIDCValue;
      });
      rows.push(item);
    }

    for (const _item of subSection.items) {
      const itemTitle = labels[_item.name] || _item.name;

      if (!title) {
        break;
      }

      console.log(subSection);

      let item: Record<string, any> = {
        title: itemTitle,
        section: section.name,
        subSection: subSection.name,
        total: _item.totals,
        path: [section.name, subSection.name, itemTitle],
        link: getLink(_item, subSection.section, grantId),
      };

      _item.years.forEach((i, index) => {
        item["year" + index] = i.value;
      });
      rows.push(item);
    }
  }
  return rows;
}

function useRowData(
  data: TableData,
  editable: boolean,
  openModal: (item: CommentedItem) => void,
  changedMTDC: IUpdateMTDCFlagsInput,
  setChangedMTDC: (a: IUpdateMTDCFlagsInput) => void,
  grantId: string
) {
  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [bottomRow, setBottomRow] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  useEffect(() => {
    console.log("updating cols");
    let totalRow: Record<string, any> = {
      section: "Total",
      title: data.totalsItem.name,
      subSection: data.totalsItem.name,
      total: data.totalsItem.totals,
      path: ["Total"],
      canEdit: false,
    };
    data.totalsItem.years.forEach((item, index) => {
      totalRow["year" + index] = item.value;
    });
    setBottomRow([totalRow]);

    const cols: ColDef[] = [
      ...data.years.map((item, index) => ({
        headerName: `Year ${index + 1}`,
        lockPosition: true,
        children: [
          {
            headerName: dayjs(item.year[0]).format("M/D/YYYY"),
            field: "year" + index,
            colSpan: () => 2,
            width: 120,
            lockPosition: true,
            editable: (params: any) => !!params.data.isOverride,
            cellClass: (params: any) => {
              if (params.node.rowPinned === "bottom") {
                return styles.footerCell;
              }
              if (params.data.isOverride) {
                return styles.editableCell;
              }
              return params.data.subSection === "" ? styles.groupCell : "";
            },
            headerClass: styles.smallerHeaderText,
            valueFormatter: ({ value }: ValueFormatterParams) =>
              value == null ? "" : numeral(value).format("$0,0.00"),
            onCellValueChanged: (params: any) => {
              console.log(params.data.sectionData);

              const way = params.data.sectionData;
              const key = params.data.sectionData;
              const dataItem = params.data.sectionData;

              const existingMTDC: IEditMTDCFlagInput[] = get(
                changedMTDC,
                [...way.split("."), key],
                []
              );
              const overdriveMTDC = existingMTDC
                .map((item) => item.id)
                .includes(dataItem.id!)
                ? existingMTDC.map((item) =>
                    item.id === dataItem.id
                      ? {
                          ...item,
                          commentIDC: dataItem.commentIDC,
                        }
                      : item
                  )
                : [
                    ...existingMTDC,
                    {
                      id: dataItem.id,
                      isOverriddenIDC: true,
                      commentIDC: dataItem.commentIDC,
                      years: dataItem.years.map((year: any) => ({
                        serialNumber: year.serialNumber,
                        overriddenIDCValue: year.overriddenIDCValue,
                      })),
                    },
                  ];
              const newMTDC = set(
                { ...changedMTDC },
                [...way.split("."), key],
                overdriveMTDC
              );
              setChangedMTDC(newMTDC);
            },
          },
          {
            headerName: dayjs(item.year[1]).format("M/D/YYYY"),
            lockPosition: true,
            width: 120,
            headerClass: styles.smallerHeaderText,
          },
        ],
      })),
      {
        headerName: "Total",
        field: "total",
        pinned: "right",
        lockPosition: true,
        width: 120,
        cellClass: (params: any) => {
          if (params.node.rowPinned === "bottom") {
            return [styles.footerCell, styles.right];
          }
          if (params.data.isOverride) {
            return [styles.editableCell, styles.right];
          }
          return params.data.subSection === ""
            ? [styles.groupCell, styles.right]
            : [styles.right];
        },
        valueFormatter: ({ value }: ValueFormatterParams) =>
          value == null ? "" : numeral(value).format("$0,0.00"),
      },
    ];

    if (editable) {
      cols.push({
        headerName: "Overridden Values",
        field: "isEditing",
        lockPosition: true,
        pinned: "right",
        editable: true,
        valueSetter: (param) => {
          const key = param.data.subSection as string;
          setSelectedRows((sl) => {
            if (sl.includes(key)) {
              return sl.filter((row) => row !== key);
            } else {
              return [...sl, key];
            }
          });

          return !!param.newValue;
        },
        cellClass: (params: any) => {
          if (params.node.rowPinned === "bottom") {
            return styles.footerCell;
          }
          if (params.data.isOverride) {
            return styles.editableCell;
          }
          return params.data.subSection === "" ? styles.groupCell : "";
        },
        valueFormatter: (params) => (params.value ? params.value : ""),
        cellRendererSelector: (params) => {
          if (params.data.canEdit) {
            return {
              component: "agCheckboxCellRenderer",
            };
          }
          if (params.data.isOverride) {
            return {
              component: CommentCellRenderer,
              params: {
                openModal,
              },
            };
          }
          return undefined;
        },
      });
    }

    setColDefs(cols);
  }, [data, editable]);

  useEffect(() => {
    console.log("updating rows", selectedRows);

    setRowData([
      ...getSectionData(data.sections.personnel, selectedRows, grantId),
      ...getSectionData(data.sections.otherPersonnel, selectedRows, grantId),
      ...getSectionData(data.sections.fringeBenefits, selectedRows, grantId),
      ...getSectionData(data.sections.travel, selectedRows, grantId),
      ...getSectionData(
        data.sections.participantSupport,
        selectedRows,
        grantId
      ),
      ...getSectionData(data.sections.equipment, selectedRows, grantId),
      ...getSectionData(data.sections.otherDirectCosts, selectedRows, grantId),
    ]);
  }, [data, editable, selectedRows]);

  return { rowData, colDefs, bottomRow };
}

function useIndirectComment(
  changedMTDC: IUpdateMTDCFlagsInput,
  setChangedMTDC: (a: IUpdateMTDCFlagsInput) => void
) {
  const [show, setShow] = useState<boolean>(false);
  const [commentedItem, setCommentedItem] = useState<CommentedItem | undefined>(
    undefined
  );

  return {
    show,
    commentedItem,
    changedMTDC,
    openModal: (item: CommentedItem) => {
      setCommentedItem(item);
      setShow(true);
    },
    closeModal: () => {
      setCommentedItem(undefined);
      setShow(false);
    },
    updateComment: (commentIDC: string) => {
      if (!commentedItem) return;
      setCommentedItem({
        ...commentedItem,
        dataItem: {
          ...commentedItem.dataItem,
          commentIDC,
        },
      });
    },
    saveComment: () => {
      if (!commentedItem) return;
      const { way, key, dataItem } = commentedItem;

      const existingMTDC: IEditMTDCFlagInput[] = get(
        changedMTDC,
        [...way.split("."), key],
        []
      );
      const overdriveMTDC = existingMTDC
        .map((item) => item.id)
        .includes(dataItem.id!)
        ? existingMTDC.map((item) =>
            item.id === dataItem.id
              ? {
                  ...item,
                  commentIDC: dataItem.commentIDC,
                }
              : item
          )
        : [
            ...existingMTDC,
            {
              id: dataItem.id,
              isOverriddenIDC: true,
              commentIDC: dataItem.commentIDC,
              years: dataItem.years.map((year) => ({
                serialNumber: year.serialNumber,
                overriddenIDCValue: year.overriddenIDCValue,
              })),
            },
          ];
      const newMTDC = set(
        { ...changedMTDC },
        [...way.split("."), key],
        overdriveMTDC
      );
      setChangedMTDC(newMTDC);
      setShow(false);
      setCommentedItem(undefined);
    },
  };
}

export function IndirectCostTable({
  data,
  height = 400,
  editable = false,
  onSubmit,
  grantId,
}: {
  data: TableData;
  grantId: string;
  height?: number;
  editable?: boolean;
  onSubmit?: (data: IUpdateMTDCFlagsInput, cb: () => void) => void;
}) {
  const [changedMTDC, setChangedMTDC] = useState<IUpdateMTDCFlagsInput>({});
  const {
    show,
    commentedItem,
    closeModal,
    updateComment,
    openModal,
    saveComment,
  } = useIndirectComment(changedMTDC, setChangedMTDC);
  const { rowData, colDefs, bottomRow } = useRowData(
    data,
    editable,
    openModal,
    changedMTDC,
    setChangedMTDC,
    grantId
  );
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Indirect Costs",
      headerComponentParams: {
        innerHeaderComponent: LogoHeaderCell,
      },
      pinned: "left",
      lockPosition: true,
      field: "title",
      cellRenderer: "agGroupCellRenderer",
      filter: "agSetColumnFilter",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: LinkCell,
      },
      cellClass: (params: any) => {
        if (params.node.rowPinned === "bottom") {
          return styles.footerCell;
        }
        if (params.data.isOverride) {
          return styles.editableCell;
        }
        return params.data.subSection === "" ? styles.groupCell : "";
      },
    };
  }, []);

  function handleSubmit() {
    const submitData = {
      ...changedMTDC,
      equipment: concat(...Object.values(changedMTDC.equipment || {})),
      participantSupport: concat(
        ...Object.values(changedMTDC.participantSupport || {})
      ),
    };
    if (onSubmit) {
      onSubmit(submitData, () => {});
    }
  }

  return (
    <div
      // define a height because the Data Grid will fill the size of the parent container
      style={{ height }}
    >
      <AgGridReact<any>
        // @ts-ignore
        autoGroupColumnDef={autoGroupColumnDef}
        groupDisplayType="singleColumn"
        rowData={rowData}
        columnDefs={colDefs}
        treeData
        getDataPath={(data) => data.path}
        pinnedBottomRowData={bottomRow}
        theme={customThemeConfig}
        getRowId={(param) => param.data.id}
      />
      {!!onSubmit && editable && (
        <div className={styles.footer}>
          <Button htmlType="button" onClick={handleSubmit} name="save-mtdc">
            Save
          </Button>
        </div>
      )}
      <IndirectCommentModal
        show={show}
        onModalClosed={closeModal}
        commentedItem={commentedItem}
        handleSaveCommentModal={saveComment}
        handleChangeComment={updateComment}
      />
    </div>
  );
}
